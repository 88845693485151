import React from 'react'

function ProductDetail() {
  return (
    <div>ProductDetail</div>
  )
}

export default ProductDetail;

//TODO; to implement ProductDetail